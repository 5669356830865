import React, { useState } from 'react';
import {
  Avatar, IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { CreditField, Credit } from 'lib/types/credits';
import { isGondolaApp } from 'lib/utils/mobileAppUtils';
import { getAvatarUrlForCredit } from 'lib/utils/imageUtils';
import { useUpgradeModal } from 'components/UpgradeModal';
import { ProfileLink } from 'components/ProfileLink';
import { MobileTooltip } from 'components/MobileTooltip';

interface Props {
  credit: CreditField | Credit;
  parentCredit?: Credit;
  parentCreditPrivate?: boolean;
  size?: 'default' | 'large';
  disabled?: boolean;
}

const SIZES = {
  default: {
    avatarSize: 40,
    parentSize: 24,
    primaryTextSize: 'text-md',
    secondaryTextSize: 'text-sm',
  },
  large: {
    avatarSize: 72,
    parentSize: 32,
    primaryTextSize: 'text-2xl',
    secondaryTextSize: 'text-xl',
  },
};

export const CreditItem = ({
  credit,
  parentCredit,
  parentCreditPrivate,
  size = 'default',
  disabled = false,
}: Props) => {
  const fakeName = 'Name Lastname';
  const hiddenPlaceholder = <span className="blur-sm">{fakeName}</span>;
  const [hide, setHidden] = useState(credit.isPrivate);
  const { showUpgradeModal } = useUpgradeModal();
  const username = hide ? '' : credit.user?.username;
  const displayName = hide ? fakeName : credit.user?.name;
  const userIsHidden = credit.user?.isHidden;
  const sizes = SIZES[size];

  /**
   * All authed users get linked to their profile.
   * Non-authed creators get linked to explore and non-authed companies do not get linked.
   * Hidden users do not get linked (ie private credits).
   */

  // eslint-disable-next-line no-underscore-dangle
  const _disabled = disabled || username === '';

  const onVisibleClick = () => (
    credit.isHidden ? showUpgradeModal('This credit has been marked as private and is only viewable to some users.')
      : setHidden(!hide)
  );

  const primaryText = () => {
    if (hide) {
      const baseText = 'This credit has been hidden by a user.';
      const title = isGondolaApp ? baseText : `${baseText} Go PRO to try it for yourself!`;
      return (
        <MobileTooltip
          placement="right"
          title={title}
          arrow
        >
          <p className="font-semibold">
            {hiddenPlaceholder}
          </p>
        </MobileTooltip>
      );
    }

    return (
      <ProfileLink
        user={credit.user}
        disabled={_disabled}
        linkToExplore={userIsHidden || credit?.user?.isHidden}
        context="credits"
      >
        <span className={_disabled ? '' : 'hover:underline'}>{displayName}</span>
      </ProfileLink>
    );
  };

  const secondaryText = () => {
    const roleName = credit?.role?.name || 'Unknown Role';
    const parentName = parentCredit?.user?.name;

    if (parentCredit && parentCredit?.user?.id) {
      if (parentCreditPrivate) {
        return (
          <>
            {roleName}
            , for
            {' '}
            {hide ? hiddenPlaceholder : parentName}
          </>
        );
      }
      return `${roleName}, for ${parentName}`;
    }
    return `${roleName}`;
  };

  if (!credit?.user && !credit.isHidden) return null;

  return (
    <div className="flex items-center py-1">
      <div className="mr-4 relative">
        <ProfileLink
          user={credit?.user}
          disabled={_disabled}
          context="credits"
          linkToExplore={userIsHidden || credit?.user?.isHidden}
        >
          <Avatar
            variant="circular"
            alt={hide ? 'HIDDEN' : displayName}
            sx={{ width: sizes.avatarSize, height: sizes.avatarSize }}
            src={getAvatarUrlForCredit(credit?.user?.id, credit?.user?.avatarUrl, hide)}
            imgProps={{ loading: 'lazy' }}
            className={_disabled ? '' : 'hover:border-2 border-orange'}
          />
          {credit && parentCredit && parentCredit?.user?.id
            && (
              <div className="absolute -right-2 -bottom-2">
                <Avatar
                  sx={{ width: sizes.parentSize, height: sizes.parentSize }}
                  alt={parentCreditPrivate ? 'HIDDEN' : parentCredit?.user?.name}
                  src={getAvatarUrlForCredit(
                    parentCredit?.user?.id, parentCredit?.user?.avatarUrl, parentCreditPrivate,
                  )}
                />
              </div>
            )}
        </ProfileLink>
      </div>
      <div>
        <div className="flex items-center">
          <div className={`${sizes.primaryTextSize} font-semibold`}>{primaryText()}</div>
          {credit.isPrivate && (
            <div className="ml-1">
              <IconButton onClick={onVisibleClick} title="show/hide the private credit">
                {!hide ? <VisibilityOff />
                  : <Visibility />}
              </IconButton>
            </div>
          )}
        </div>
        <div className={`${sizes.secondaryTextSize} text-gray-500`}>{secondaryText()}</div>
      </div>
    </div>
  );
};
