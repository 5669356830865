import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Avatar } from '@mui/material';
import {
  Job, JobLocationType, JobPayDisplay, JobPayType, JobType,
} from 'lib/types/jobs';
import { jobLocationTypes, jobTypes } from 'lib/constants/jobs';
import { getPayInfo } from 'lib/utils/jobs';
import { imageAvatarUrl } from 'lib/utils/imageUtils';
import { formatLocalString } from 'lib/utils/datetimeUtils';
import { User } from 'lib/types/users';
import { WORK_FOR_US_NAME } from 'lib/constants/teams';
import { buildJobPath, userIsAdmin } from 'lib/helpers';
import { A } from 'components/A';
import { DeleteApplication } from 'features/JobDetail/DeleteApplication';
import Link from 'next/link';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { trackClickInternalApplicationEvent } from 'lib/hooks/useEventTracking';
import { ProfileLink } from 'components/ProfileLink';
import { JobCardMenu } from './JobCardMenu';

interface Props {
  account: User;
  job: Job;
}

export const JobCard = ({
  account, job,
}: Props) => {
  const router = useRouter();
  const isProspectJob = job.name === WORK_FOR_US_NAME;
  const jobPath = isProspectJob ? `/${account.username}/work-for-us` : buildJobPath(job);
  const API = useApi();
  const currentUser = useCurrentUser();
  const [currentUserIsTeamMember, setCurrentUserIsTeamMember] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setCurrentUserIsTeamMember(
        !!currentUser.teamPermissions?.some((team) => team.id === job.teamId),
      );
    }
  }, [currentUser]);

  const click = async () => {
    if (!job.externalLink) {
      // track internal apply
      await trackClickInternalApplicationEvent(API, job.id);
    }
    router.push(`${jobPath}?apply=1`);
  };

  const getApplyButton = () => {
    if (job.appliedAt) {
      return (
        <>
          <span className="bg-lightgray px-4 py-2 text-sm font-semibold rounded-md">
            Applied on
            {' '}
            {formatLocalString(job.appliedAt)}
          </span>
          <DeleteApplication jobId={job.id} onCancel={router.reload} />
        </>
      );
    }

    return <button type="button" className="btn-primary" onClick={click}>Apply</button>;
  };

  const formatJobType = (type: JobType) => {
    let jobTypeColor = 'bg-goldenRange';
    if (type === 'ft') {
      jobTypeColor = 'bg-alpineTeal';
    } else if (type === 'pt') {
      jobTypeColor = 'bg-darkorange';
    }
    return (
      <span className={`mr-2 ${jobTypeColor} px-4 text-white text-sm font-bold uppercase`}>
        {jobTypes[type]}
      </span>
    );
  };

  return (
    <div className={`w-full h-full border rounded-lg p-8 ${isProspectJob ? 'border-orange border-2' : ''}`}>
      <div className="flex space-x-2">
        <div className="w-1/5">
          <Avatar
            sx={{ width: 50, height: 50, margin: 'auto' }}
            variant="circular"
            alt={account.name}
            src={imageAvatarUrl(account.avatarUrl, 100)}
          />
        </div>
        <div className="w-4/5">
          <div className="text-sm">
            @
            {account.username}
          </div>
          <ProfileLink
            user={account}
            username={account.username}
            linkToExplore={account.isHidden}
            context="job-card"
          >
            <div className="text-lg font-semibold mb-4 hover:underline">
              {account.name}
            </div>
          </ProfileLink>
        </div>
        {((currentUserIsTeamMember && job.teamId) || (userIsAdmin(currentUser))) && (
          <div>
            <JobCardMenu job={job} />
          </div>
        )}
      </div>
      <Link href={buildJobPath(job)} className="hover:underline">
        <div className="text-xl font-bold mb-2">{isProspectJob ? 'Join our prospects list!' : job.name}</div>
      </Link>
      {!isProspectJob && (
        <div className="mb-2 text-sm" suppressHydrationWarning>
          {formatJobType(job.jobType as JobType)}
          {job.createdAt && `Posted on ${formatLocalString(job?.createdAt)}`}
        </div>
      )}
      <div className="mb-4 overflow-hidden h-24">
        {job.description}
      </div>
      {!isProspectJob && (
        <>
          <div className="flex justify-between">
            <div className="font-semibold">
              <div>
                {jobLocationTypes[job.locationType as JobLocationType]}
              </div>
              {job.locations?.map((l) => (
                <div key={l.id}>{l.name}</div>
              ))}
            </div>
            <div className="font-semibold text-right">
              {getPayInfo(
                job.payType as JobPayType,
                job.payDisplay as JobPayDisplay,
                job.payAmount, job.payMin, job.payMax,
              )}
            </div>
          </div>
        </>
      )}
      <div className="mt-4 flex flex-wrap gap-2">
        <A href={jobPath} className="btn-primary-outlined">View</A>
        {getApplyButton()}
      </div>
    </div>
  );
};
