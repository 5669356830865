import { Avatar, Tooltip } from '@mui/material';
import { ProfileLink } from 'components/ProfileLink';
import { CreatorCollaborator } from 'lib/types';
import { CreatorCardClickContext } from 'lib/types/events';
import { imageAvatarUrl } from 'lib/utils/imageUtils';

interface Props {
  /**
   * The collab is the user whose avatar and name will be displayed.
   */
  collab: CreatorCollaborator;
  /**
   * If the optional `username` prop is provided, the avatar will link to
   * the profile with the username specified rather than the username of
   * the `collab`.
   */
  username?: string;
  query?: { [key: string]: string };
  disabled?: boolean;
  context?: CreatorCardClickContext;
}
export const CollabAvatar = ({
  collab,
  username,
  query,
  disabled,
  context,
}: Props) => (
  <ProfileLink
    key={collab.id}
    username={username}
    user={collab}
    query={query}
    disabled={disabled}
    linkToExplore={collab.isHidden || !username}
    context={context}
  >
    <Tooltip title={collab.name} key={collab.id} placement="top" arrow>
      <Avatar
        src={imageAvatarUrl(collab.avatarUrl, 80)}
        alt={collab.name}
        className={!disabled ? 'hover:border-2 border-orange' : ''}
      />
    </Tooltip>
  </ProfileLink>
);
