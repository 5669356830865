import qs from 'querystring';
import { compact } from 'lodash';
import { SearchResultResponse } from 'lib/types/globalSearch';
import { DISPUTED_CREDIT_STATUS } from 'lib/constants';
import {
  AdminMilestoneResponse,
  AdminRolesResponse,
  AdminTagValuesResponse,
  CreatorTagResponse,
  FailedPostResponse,
  MatchResponse,
  ReferralsResponse,
  SimilarImageResponse,
  NetworkAccountsSearchResponse,
  PostSearchResponse,
  ScraperStatusResponse,
  DeactivationCheckResponse,
  JobSearchResponse,
  PostServiceStats,
  MyGondolaResponse,
  MyGondolaMilestones,
  NotificationStatus,
  ConceptSearchResults,
  ProjectSearchResults,
  PostingAccountsSearchResponse,
  ReplayAICardsResponse,
} from 'server/api/types';
import { TeamAccountSettings } from 'server/api/services/teams/index';
import { PostSearchCriteria } from 'lib/types/filters';
import { GoogleAuthPayload } from 'server/api/services/auth/google';
import { CreatorSearchCriteria, CreatorSearchESResponse } from 'server/api/services/creatorSearch/types';
import {
  AnalyticsCriteria, AnalyticsData, AnalyticsDataStats, AnalyticsAccount,
} from 'server/api/services/analytics/types';
import { TokenExchangeResponse } from 'contentAPIs/google/types';
import { ManualPost } from 'server/api/schemas/posts';
import {
  ApplicationQuestion, Job, JobApplication, NewApplication,
} from 'lib/types/jobs';
import { JobSchemaType } from 'server/api/schemas/jobs';
import Stripe from 'stripe';
import { Report, ReportType } from 'lib/types/reports';
import {
  NetworkStatus, Link, Account, Track,
  AppleWebSignInCredential, HeardAboutOption,
} from 'lib/types';
import MatchPost from 'server/models/MatchPosts';
import { CacheSearchCriteria } from 'lib/types/caches';
import { GlobalAnalyticsResponse } from 'server/es/indexes/posts/search/types';
import {
  Audit,
  History,
  BlogPost,
  FeedbackType,
  KeyRole,
  Notification,
  Pin,
  Post,
  Role,
  RoleTag,
  Milestone,
  EmailCredential,
  List,
  ListMember,
  AddList,
  ManagedAccount,
  Stats,
  ListVote,
  Subscription,
  EventSignup,
  SensitiveContentTypes,
  OpenAIImagesResponse,
  Media,
  ContentList,
} from './types/index';
import {
  AdminViewUserResponse,
  CreatorsListUser,
  NetworkUser,
  User,
  UserSettings,
  UserData,
  NextStep,
  CredentialsForEmail,
  AllNetworks,
} from './types/users';
import {
  MatchAdminQuery, SimilarImagesQuery, PostingAccountCriteria, ScraperAdminCriteria,
} from './types/query';
import {
  EditCredit, Credit, CreditType, DisputedCredit, CreateCreditsPayload,
} from './types/credits';
import { TagType, TagValue } from './types/tags';
import { TrackedEvent } from './hooks/useEventTracking';
import {
  InvoicePreview, AdminTeamQueryParams,
  Team, TeamAccount, TeamMember, TeamSubscription, TeamSubscriptionInvoice, ExternalApiPermission,
} from './types/teams';
import { StripeCustomerResponse, StripeSessionResponse } from './types/stripe';
import { StripePaymentMethod } from './types/paymentMethods';
import { ACTIONS } from './types/events';
import { WorkedTogetherResponse, LimitedUseResponse } from './types/workedTogether';
import { Concept, Project } from './types/concepts';
import { CompareResultsResponse, MatchMetrics, MediaComparisonGroup } from './types/mediaComparisons';
import { ApiCacheResponse } from './types/caches';
import { ScraperAccount } from './types/scraper';
import {
  AutoCredit, AutoCreditCount, Connection, PostingAccount,
} from './types/postingAccounts';
import {
  PostMetrics, PostMetricsUpdate, PostToImport, PostWithDelta,
} from './types/posts';

const REQUEST_METHODS: { [method: string]: string } = {
  GET: 'GET',
  DELETE: 'DELETE',
  POST: 'POST',
  PUT: 'PUT',
};

interface Query {
  [key: string]: string | number | (string | number) | undefined;
}

const FORTY_FIVE_SECONDS = 30000;
const ONE_MINUTE = 60000;

/**
 * We're going to automatically throw an error when a fetch takes longer than 45 seconds.
 * Many slow requests now have 20 second timeouts on the backend so we'll hopefully get a timeout
 * error back before then.
 * */
function fetchWithTimeout(
  url: URL | string, options?: any, timeout = FORTY_FIVE_SECONDS,
): Promise<Response> {
  const errorMessage = 'Timeout accessing Gondola. Try again later.';
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) => setTimeout(() => reject(new Error(errorMessage)), timeout)),
  ]) as Promise<Response>;
}

/**
 * ABORT_REQUEST_CONTROLLERS stores AbortController by key. Currently,
 * we construct a string ${method}-${path} e.g. 'get-/posts/explore'
 *
 * via https://pgarciacamou.medium.com/using-abortcontroller-with-fetch-api-and-reactjs-8d4177e51270
 */
const ABORT_REQUEST_CONTROLLERS = new Map<string, AbortController>();

/**
 * abortRequest looks up an AbortController via its string key
 * and sends an abort signal.
 */
export function abortRequest(key: string, reason = 'CANCELLED') {
  ABORT_REQUEST_CONTROLLERS.get(key)?.abort?.(reason);
}

/**
 * abortAndGetSignal aborts a request (via `abortRequest`) and then
 * sets a new AbortController for the same key in ABORT_REQUEST_CONTROLLERS.
 */
function abortAndGetSignal(key: string | undefined) {
  if (!key) {
    return undefined;
  }
  abortRequest(key); // abort previous request, if any
  const newController = new AbortController();
  ABORT_REQUEST_CONTROLLERS.set(key, newController);
  return newController.signal;
}

export class ApiError extends Error {
  errorType: number | undefined;

  constructor(errResponse: any) {
    super(errResponse?.message || 'Unknown API error');
    this.errorType = errResponse?.errorType || -1;
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

export const buildUrl = (path: string, query = {}) => compact([`/api${path}`, qs.stringify(query)]).join('?');

export const apiClient = (currentUser?: User | null) => {
  const buildHeaders = (ctx: any, isJsonBody: boolean) => {
    const token = currentUser?.token || null;
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    if (token) {
      headers.append('Authorization', token);
    }
    const hasCookie = ctx && ctx?.req && ctx?.req?.headers?.cookie;
    if (hasCookie) {
      headers.append('cookie', ctx.req.headers.cookie);
    }
    if (isJsonBody) {
      headers.append('Content-Type', 'application/json');
    }

    if (typeof localStorage !== 'undefined') {
      const featureFlag = localStorage.getItem('featureFlag');
      /** Send the feature flag (if any) as a request header */
      if (featureFlag) {
        headers.append('Feature-Flag', featureFlag);
      }
    }
    return headers;
  };

  const buildRequestOptions = (
    method: string,
    query: any,
    body: any,
    ctx: any,
    isJsonBody: boolean,
    signalKey: string | undefined,
  ) => {
    const headers = buildHeaders(ctx, isJsonBody);
    const methodsNoBody = [REQUEST_METHODS.GET, REQUEST_METHODS.DELETE];

    const signal = abortAndGetSignal(signalKey);
    if (methodsNoBody.includes(method)) {
      return {
        headers,
        method,
        signal,
      };
    }
    return {
      headers,
      method,
      body,
      signal,
    };
  };

  async function makeRequest<T>(
    method: string, path: string, query = {}, body: any, ctx: any, isJsonBody = false,
  ): Promise<T> {
    const url = buildUrl(path, query);
    const signalKey = ctx?.useAbort ? `${method}-${path}` : undefined;
    const timeout = ctx?.customTimeout ? ctx?.customTimeout : undefined;
    const requestOptions = buildRequestOptions(method, query, body, ctx, isJsonBody, signalKey);

    const response = await fetchWithTimeout(
      url.toString(),
      requestOptions,
      timeout,
    );

    let json;
    try {
      json = await response.json();
    } catch (e: any) {
      if (e.name === 'AbortError') {
        throw (e);
      }
      // if there is a problem parsing the json and there was already an issue with the response
      // throw an API error
      if (!response.ok) {
        throw new ApiError({ message: response.statusText });
      }
    }
    if (json?.success) {
      return (ctx && ctx.returnFullJson) ? json : json.data;
    }
    throw new ApiError(json);
  }

  function hasToken() {
    return !!currentUser?.token;
  }

  async function get<T>(path: string, query?: any, ctx?: any): Promise<T> {
    return makeRequest<T>(
      REQUEST_METHODS.GET, path, query, {}, ctx,
    );
  }

  async function put<T>(path: string, body?: any, ctx?: any): Promise<T> {
    return makeRequest<T>(
      REQUEST_METHODS.PUT, path, {}, JSON.stringify(body || {}), ctx, true,
    );
  }

  async function post<T>(path: string, body?: any, ctx?: any): Promise<T> {
    return makeRequest<T>(
      REQUEST_METHODS.POST, path, {}, JSON.stringify(body || {}), ctx, true,
    );
  }

  async function httpDelete<T>(path: string, query?: any, ctx?: any): Promise<T> {
    return makeRequest<T>(
      REQUEST_METHODS.DELETE, path, query, {}, ctx,
    );
  }

  async function putFile<T>(path: string, data: any): Promise<T> {
    return makeRequest<T>(
      REQUEST_METHODS.PUT, path, {}, data, null,
    );
  }

  async function postFile<T>(path: string, data: any): Promise<T> {
    return makeRequest<T>(
      REQUEST_METHODS.POST, path, {}, data, null,
    );
  }

  async function getCursorPagination<T>(
    path: string, query: any, ctx = {}, before?: string, after?: string,
  ): Promise<T> {
    if (before) {
      const queryWithBeforeCursor = { ...query, ...{ before } };
      return get(path, queryWithBeforeCursor, ctx);
    }
    if (after) {
      const queryWithAfterCursor = { ...query, ...{ after } };
      return get(path, queryWithAfterCursor, ctx);
    }
    return get(path, query, ctx);
  }

  // ----- api/auth
  const getAuth = async (ctx?: any): Promise<User | null> => {
    try {
      return get('/auth', null, ctx);
    } catch (e) {
      // If this fails for any reason, return null
      return null;
    }
  };

  // ----- api/roles
  const listAllRoles = async (): Promise<Role[]> => get('/roles');
  const listAllRolesAdmin = async (query: any): Promise<AdminRolesResponse> => get('/roles/admin', query);
  const getUserRoles = async (
    userId: number,
    onlyUserRoles: boolean = false,
  ): Promise<Role[]> => get(
    `/roles/user/${userId}`, { onlyUserRoles },
  );
  const createSuggestedRole = async (suggestedRole: string, exampleTwitterHandle: string) => post('/roles/suggest', { suggestedRole, exampleTwitterHandle });
  const createRole = async (role: Role): Promise<Role> => post('/roles', role, { returnFullJson: true });
  const updateRole = async (role: Role): Promise<Role> => put(`/roles/${role.id}`, role, { returnFullJson: true });
  const getRoleById = async (roleId: number): Promise<Role> => get(`/roles/${roleId}`);
  const getUserHiddenKeyRoles = async (userId: number): Promise<KeyRole[]> => get(`/roles/${userId}/userHiddenKeyRoles`);
  const createHiddenKeyRole = async (roleId: number): Promise<Role> => post(`/roles/${roleId}/addHiddenKeyRole`);
  const deleteHiddenKeyRole = async (roleId: number) => post(`/roles/${roleId}/removeHiddenKeyRole`);
  const listAllRoleTags = async (): Promise<RoleTag[]> => get('/roles/tags');
  const getRoleTagSuggestions = async (roleTagId: number): Promise<RoleTag[]> => get(`/roles/${roleTagId}/suggest`);

  // ----- api/tags
  const listTagTypes = async (): Promise<TagType[]> => get('/tags/types');
  const listAllTagValuesAdmin = async (query: any): Promise<AdminTagValuesResponse> => get('/tags/admin', query);
  const tagValuesSearch = async (query: string, tagTypeId: number): Promise<TagValue[]> => get(
    '/tags/values/search/value', { query, tagTypeId },
  );
  const createNewTagValue = async (
    tagTypeId: number, value: string, weighting?: number | null,
  ): Promise<TagValue> => post(
    '/tags', { value, tagTypeId, weighting },
  );
  const updateTagValue = async (tagValueId: number, tagValue: Partial<TagValue>) => put(`/tags/values/${tagValueId}`, tagValue, { returnFullJson: true });
  const deleteTagValue = async (tagValueId: number) => httpDelete(`/tags/values/${tagValueId}`);
  const createBulkTags = async (payload: any) => post('/tags/bulk', payload);
  const listUserTags = async (userId: number, tagTypeId: number): Promise<TagValue[]> => get(`/tags/${userId}/`, { tagTypeId });
  const suggestTagsForPostId = async (
    tagTypeId: number, postId: number,
  ): Promise<TagValue[]> => get(
    `/tags/suggest/${postId}`, { tagTypeId },
  );
  const getCreatorTagsByUser = async (userId: number): Promise<CreatorTagResponse> => get(`/tags/${userId}/creatorTagsByUser`);
  const addCreatorTag = async (tagValue: string) => post('/tags/creatorTag', {
    value: tagValue,
  });
  const deleteCreatorTag = async (tagId: number) => httpDelete(`/tags/creatorTag/${tagId}`);

  // ----- api/admin/reports
  const listReports = async (): Promise<ReportType[]> => get('/admin/reports');
  const getReport = async (key: string): Promise<Report> => get('/admin/reports/run', { type: key }, { useAbort: true });

  // ----- api/posts
  const createPinnedPost = async (postId: number): Promise<Post> => post(`/posts/${postId}/pin`);
  const deletePinnedPost = async (postId: number) => post(`/posts/${postId}/unpin`);
  const getPostById = async (postId: number): Promise<Post> => get(`/posts/${postId}`);
  const getPostByUrl = async (url: string): Promise<Post> => get('/posts/url', { url });
  const createPostByUrl = async (url: string): Promise<Post> => post('/posts', { url });
  const searchAccountNames = async (query: string): Promise<string> => get('/posts/accounts', { q: query });
  const deletePost = async (postId: number) => httpDelete(`/posts/${postId}`);
  const scrapePostMetrics = async (postId: number): Promise<PostWithDelta> => get(`/posts/${postId}/scrape`);
  const queueScrapePost = async (postId: number): Promise<void> => get(`/posts/${postId}/enqueueScrape`);
  const addTagToPost = async (postId: number, tagTypeId: number, tagValue: string, tagValueId?: number) => post(`/posts/${postId}/tags`, {
    tagTypeId,
    tagValue,
    tagValueId,
  });
  const deleteTagFromPost = async (postId: number, tagId: number) => httpDelete(`/posts/${postId}/tags/${tagId}`);
  const toggleIsLive = async (postId: number, isLive: boolean) => post(`/posts/${postId}/isLive`, { isLive });
  const toggleStatsPaused = async (postId: number, statsPaused: boolean) => post(`/posts/${postId}/statsPaused`, { statsPaused });
  const toggleIsFeatured = async (postId: number, isFeatured: boolean) => post(`/posts/${postId}/featured`, { isFeatured });
  const toggleSensitiveContent = async (postId: number, sensitiveContent: SensitiveContentTypes) => post(`/posts/${postId}/sensitiveContent`, { sensitiveContent });
  const createManualPost = async (postData: ManualPost): Promise<Post> => post('/posts/manual', postData);
  const uploadMedia = async (file: FormData, filename: string, folder: string): Promise<Media[]> => postFile(`/uploads/media?filename=${filename}&folder=${folder}`, file);
  const uploadCompressedMedia = async (file: FormData, filename: string, folder: string): Promise<Media[]> => postFile(`/uploads/compressedMedia?&folder=${folder}&filename=${filename}`, file);
  const findMatchesForUpload = async (pHash: string, distanceThreshold: number): Promise<Post[]> => get('/posts/findSimilar', { pHash, distanceThreshold });
  const findMatchesForUploadViaUrl = async (url: string, minScore?: number): Promise<CompareResultsResponse> => get('/posts/compare/findSimilarUrl', { url, minScore });
  const findMatchesForUploadViaImage = async (mediaId: number, minScore?: number): Promise<CompareResultsResponse> => get('/posts/compare/findSimilarImg', { mediaId, minScore });
  const getMediaComparisonResults = async (publicId: string): Promise<CompareResultsResponse> => get(`/posts/compare/${publicId}`);
  const runMediaComparison = async (url: string | undefined, mediaId?: number, minScore?: number): Promise<CompareResultsResponse> => post('/posts/compare', { mediaId, url, minScore });
  const updateMediaComparison = async (comparisonId: number, creditedUserId: number, creditedRoleId: number, creditAlreadyMatchedPosts: boolean): Promise<CompareResultsResponse> => put(`/posts/compare/${comparisonId}`, { creditedUserId, creditedRoleId, creditAlreadyMatchedPosts });
  const updateMediaComparisonGroup = async (groupId: number, comparison: any): Promise<CompareResultsResponse> => put(`/posts/compare/group/${groupId}`, comparison);
  const updateMediaComparisonGroupCredits = async (groupId: number, creditedUserId: number, creditedRoleId: number, creditAlreadyMatchedPosts: boolean): Promise<CompareResultsResponse> => put(`/posts/compare/group/${groupId}/credits`, { creditedUserId, creditedRoleId, creditAlreadyMatchedPosts });
  const createMediaComparisonGroup = async (group: Partial<MediaComparisonGroup>): Promise<MediaComparisonGroup> => post('/posts/compare/group', group);
  const getMediaComparisonGroup = async (publicId: string): Promise<MediaComparisonGroup> => get(`/posts/compare/groups/${publicId}`);
  const getMediaComparisonGroups = async (): Promise<MediaComparisonGroup[]> => get('/posts/compare/groups');
  const deleteMediaComparisonGroup = async (groupId: number) => httpDelete(`/posts/compare/group/${groupId}`);
  const addMediaToComparisonGroup = async (groupId: number, mediaIds: number[]): Promise<MediaComparisonGroup> => post(`/posts/compare/group/${groupId}/media`, { mediaIds });
  const deleteComparisonFromComparisonGroup = async (groupId: number, comparisonId: number): Promise<MediaComparisonGroup> => httpDelete(`/posts/compare/group/${groupId}/comparison/${comparisonId}`);
  const findPostsViaText = async (text: string): Promise<Post[]> => get('/posts/compare/findImagesFromText', { text });
  const reindexPost = async (postId: number): Promise<void> => post(`/posts/${postId}/reindex`);
  const getTrendingPosts = async (): Promise<Post[]> => get('/posts/trending');
  const getConceptsForPost = async (postId: number): Promise<Concept[]> => get(`/posts/${postId}/concepts`);
  const getCompareUses = async (): Promise<LimitedUseResponse> => get('/posts/compare/uses');
  const ignoreSuggestedCredit = async (postId: number): Promise<void> => httpDelete(`/posts/${postId}/suggestedCredits`);

  // ----- api/stripe
  const getStripeSetup = async (): Promise<{ publishableKey: string }> => get('/stripe/setup');
  const createStripeCheckout = async (coupon?: string, promocode?: string, isResuming?: boolean): Promise<StripeSessionResponse> => post('/stripe/checkout', { coupon, promocode, isResuming });
  const createStripePortal = async (returnTo: string): Promise<StripeSessionResponse> => post('/stripe/portal', { returnTo });
  const giveFreeMonth = async (userId: number) => post('/stripe/giveFreeMonth', { userId });
  const endTrialEarly = async (): Promise<Subscription> => get('/users/subscription/endTrial');

  // ----- api/users
  const createUser = async (user: Partial<User>): Promise<User> => post('/users', user);
  const listAllUsers = async (params: any): Promise<AdminViewUserResponse> => get('/users/admin/search', params, { returnFullJson: true });
  const getUserById = async (userId: number): Promise<User> => get(`/users/${userId}`);
  const getUserByUsername = async (username: string): Promise<User> => get(`/users/username/${username}`);
  const getUserByPostId = async (postId: number): Promise<User> => get(`/users/post/${postId}`);
  const suggestUsersForPost = async (postId: number, creditType: CreditType): Promise<User[]> => get(`/users/forPost/${postId}`, { creditType });
  const getSuggestedUserToCredit = async (postId: number): Promise<User[]> => get(`/users/credits/post/${postId}`);

  /** Update your own user */
  const updateOwnUser = async (user: Partial<User>): Promise<User> => {
    if (!currentUser) {
      throw new Error('Must be logged in to update your user');
    } else {
      return put(`/users/${currentUser.id}`, user);
    }
  };

  /** Update another user you have permission to update */
  const updateOtherUser = async (userId: number, user: Partial<User>): Promise<User> => put(`/users/${userId}`, user);
  const updateUserViaAdmin = async (user: Partial<User>) => put(`/users/admin/${user.id}`, user, { returnFullJson: true });
  const claimUserReferral = async (referralCode: string) => post(`/users/claimReferral/${referralCode}`);
  const getUserByReferralCode = async (referralCode: string): Promise<User> => get(`/users/byReferralCode/${referralCode}`);
  const createFeedback = async (feedback: FeedbackType) => post('/users/feedback', feedback);
  const getUserSettings = async (): Promise<UserSettings> => get('/userSettings');
  const updateUserSettings = async (settings: UserSettings): Promise<UserSettings> => put('/userSettings', settings);
  const getTwitterUser = async (query: string): Promise<NetworkUser> => get(`/twitterProxy/${query}`);
  const searchUsers = async (query: string, postId?: number): Promise<User[]> => get('/users/search', {
    query,
    postId,
  });
  const searchUsersForTeamMember = async (query: string): Promise<User[]> => get('/users/search/forTeamMember', { query });
  const updateUsername = async (username: string): Promise<User> => get('/users/username', { username });
  const updateAvatar = async (userId: number, fileData: FormData): Promise<User> => putFile(`/users/update_avatar/${userId}`, fileData);

  const getTwitterFollowers = async (username: string) => get(`/twitterProxy/${username}/topFollowers`);

  const getMyGondolaStats = async (): Promise<MyGondolaResponse> => get('/users/stats/myGondola');

  const searchCreators = async (
    query: CreatorSearchCriteria,
  ): Promise<CreatorSearchESResponse> => get('/creators/search', query, { returnFullJson: true, useAbort: true });

  const featuredCreators = async (
    query: CreatorSearchCriteria,
  ): Promise<CreatorSearchESResponse> => get('/creators/featured', query, { returnFullJson: true, useAbort: true, customTimeout: 500 });

  const exportCreatorsCSVReport = async (query: CreatorSearchCriteria, title: string): Promise<PostSearchResponse> => get('/creators/csv', { ...query, title });

  const topWeeklyCreators = async (roleIds: number[], sort: string, size: number): Promise<CreatorsListUser[]> => get('/creators/weeklytop', { roleIds, sort, size });

  const sendDirectMessage = async (toUserId: number, subject: string, body: string, ccEmail: boolean): Promise<void> => post('/users/message', {
    toUserId,
    body,
    subject,
    ccEmail,
  });
  const getUserNextSteps = async (): Promise<NextStep | undefined> => {
    try {
      const res = await get<Promise<NextStep>>('/users/nextstep');
      return res;
    } catch (e: any) {
      // noop
    }
    return undefined;
  };
  const onboardUser = async (): Promise<void> => post('/users/onboard');
  const triggerWelcomeEmail = async (): Promise<void> => post('/users/welcome');
  const getCollaborators = async (userId: number): Promise<User[]> => get(`/users/${userId}/collaborators`);
  const getAllUserReferrals = async (query: Query): Promise<ReferralsResponse> => get('/admin/referrals', query);
  const checkDeactivateUser = async (userId: number): Promise<DeactivationCheckResponse> => get(`/users/${userId}/deactivation`);
  const deactivateUser = async (userId: number) => httpDelete(`/users/${userId}`);
  const queueReplayRecalc = async (userId: number) => post(`/admin/replayRecalc/${userId}`);
  const saveUserData = async (userData: Partial<UserData>) => post('/users/userData', { userData });
  const getUserHistory = async (userId: number): Promise<History[]> => get(`/admin/history/${userId}`, {}, { useAbort: true });
  const getUsernameAvailability = async (username: string): Promise<AllNetworks> => get(`/users/username/${username}/available`);
  const claimPersonalIgUsername = async (username: string): Promise<User> => get(`/users/username/${username}/claimPersonal`);
  const uploadResume = async (file: FormData, filename: string, folder: string): Promise<Media[]> => postFile(`/users/uploadResume?filename=${filename}&folder=${folder}`, file);
  const mergeUsers = async (keepUserId: number, duplicateUserId: number, keepUsername?: string) => post('/admin/mergeUsers', { keepUserId, duplicateUserId, keepUsername });
  const getProfileStats = async (userId: number): Promise<PostServiceStats> => get(`/users/profileStats/${userId}`);
  const reindexUserPosts = async (userId: number): Promise<void> => post(`/users/${userId}/reindexPosts`);
  const getHeardAboutOptions = async (): Promise<string[]> => get('/users/data/heardabout');

  // ----- api/credits
  const createCredits = async (payload: CreateCreditsPayload): Promise<PostingAccount> => post('/credits/bulk', payload);
  const updateCredit = async (creditId: number, credit: EditCredit) => put<Credit>(`/credits/${creditId}`, credit);
  const deleteCredit = async (creditId: number) => httpDelete(`/credits/${creditId}`);
  const reportCredit = async (disputedCredit: DisputedCredit) => post(`/credits/${disputedCredit.creditId}/report`, {
    ...disputedCredit,
    status: DISPUTED_CREDIT_STATUS.open,
  });
  const getCreditCreator = async (creditId: number): Promise<Credit> => get(`/credits/${creditId}/creator`);

  // ----- api/search
  const getTopSearch = async (query: string) => get<SearchResultResponse>('/topsearch', { query }, { useAbort: true });
  const getProfileFilterSearch = async (query: string, userId: number) => get<SearchResultResponse>(`/filters/${userId}`, { query }, { useAbort: true });
  const getDashboardFilterSearch = async (query: string, accountId: number | undefined, teamId: number | undefined) => get<SearchResultResponse>('/filters/dashboard', { query, accountId, teamId }, { useAbort: true });
  const getCreatorsFilterSearch = async (query: string, userTypeId?: number) => get<SearchResultResponse>('/filters/creators', { query, userTypeId }, { useAbort: true });
  const getCollaboratorRolesFilterSearch = async (
    query: string,
  ) => get<SearchResultResponse>('/filters/collaborators', { query }, { useAbort: true });

  // ----- auditlogs and event tracking
  const listCreditAuditsForPost = async (postId: number): Promise<Audit[]> => get('/auditLogs/credits', {
    postId,
    size: 100,
  });
  const listTagAuditsForPost = async (postId: number): Promise<Audit[]> => get('/auditLogs/tags', { postId });
  const trackEvent = async (event: TrackedEvent) => post('/track', event);
  const trackEmailPwError = async (email: string, data: object) => post('/track', {
    action: ACTIONS.signinError,
    target: 'AuthEmail',
    targetId: email,
    fields: data,
  });
  const trackGoogleAuthError = async (data: object) => post('/track', {
    action: ACTIONS.signinError,
    target: 'AuthGoogle',
    fields: data,
  });

  // ----- failedposts
  const listFailedPosts = async (query: any): Promise<FailedPostResponse> => get('/failedposts', query, { returnFullJson: true });

  // ------ blog
  const listBlogPosts = async (query: any): Promise<{data: BlogPost[]; meta: any}> => get('/blog', query);
  const refreshBlogPosts = async () => post('/blog/refresh');

  // ------ notifications
  const getNotificationsStatus = async (): Promise<NotificationStatus> => get('/notifications/status');
  const getNotificationsByUser = async (): Promise<Notification[]> => get('/notifications/user');
  const archiveNotification = async (id: string): Promise<void> => put(`/notifications/${id}/archive`, { status: 'archived' });
  const markNotificationRead = async (id: string): Promise<void> => put(`/notifications/${id}/read`, { status: 'read' });
  const markAllNotificationsRead = async (): Promise<void> => put('/notifications/all/read');

  // ------ postMetrics
  const getAllPostMetricsForPost = async (postId: number): Promise<PostMetrics[]> => get(`/postMetrics/post/${postId}`);
  const updatePostMetricsAndPost = async (postId: number, metrics: PostMetricsUpdate): Promise<Post> => put(`/postMetrics/post/${postId}`, { metrics });

  // ------ matches
  const getMatch = async (matchId: string): Promise<Post[]> => get(`/matches/${matchId}`);
  const getAllMatchesAdmin = async (query: MatchAdminQuery): Promise<MatchResponse> => get('/matches/admin', query, { useAbort: true });
  const addPostsToMatch = async (postIds: number[], matchId?: string, mediaComparisonResultId?: number): Promise<MatchPost[]> => post('/matches', { postIds, matchId, mediaComparisonResultId });
  const deleteMatchPost = async (matchPostId: string): Promise<boolean | undefined> => httpDelete(`/matches/${matchPostId}`);
  const unflagMatch = async (matchId: string) => post(`/matches/${matchId}/unflag`);
  const mergeMatches = async (matchId: string, matchIds: string[]) => post(`/matches/${matchId}/merge`, { matchIds });
  const getMatchStatsDifference = async (matchId: string): Promise<Stats | undefined> => get(`/matches/${matchId}/statDifference`);
  const copyOriginalPostCreditsToAll = async (matchId: string, postId: number) => post(`/matches/${matchId}/copyCredits`, { postId });
  const getMatchMetrics = async (matchId: string): Promise<MatchMetrics> => get(`/matches/${matchId}/metrics`);

  // ------ filter posts
  const getExplorePosts = async (query?: PostSearchCriteria): Promise<PostSearchResponse> => get('/posts/explore', query, { returnFullJson: true, useAbort: true });
  const getManagerPosts = async (query?: PostSearchCriteria): Promise<PostSearchResponse> => get('/posts/manager', query, { returnFullJson: true, useAbort: true });
  const getProfilePosts = async (profileUserId: number, query?: PostSearchCriteria): Promise<PostSearchResponse> => get(`/posts/profile/${profileUserId}`, query, { returnFullJson: true, useAbort: true });
  const getPinnedPosts = async (profileUserId: number): Promise<Pin[]> => get(`/posts/profile/${profileUserId}/pinned`);

  const exportCSVReportForProfile = async (profileUserId: number, query?: PostSearchCriteria): Promise<void> => get('/posts/report', { ...query, profileUserId });
  const exportCSVReportForExplore = async (title: string, query?: PostSearchCriteria): Promise<void> => get('/posts/report', { ...query, title });

  const getPostVectorViaText = async (text: string, from?: number): Promise<PostSearchResponse> => get('/postVector', { text, from }, { returnFullJson: true });

  // ------ analytics
  const getDashboard = async (query?: AnalyticsCriteria): Promise<AnalyticsData[]> => get('/analytics/dashboard', query, { customTimeout: ONE_MINUTE });
  const getDashboardStats = async (query?: AnalyticsCriteria): Promise<AnalyticsDataStats> => get('/analytics/dashboard/stats', query, { customTimeout: ONE_MINUTE });
  const clearDashboardCache = async (teamId: number) => httpDelete(`/analytics/dashboard/${teamId}/cache`);
  const getDashboardAccount = async (teamId?: number, accountId?: number): Promise<AnalyticsAccount> => get('/analytics/dashboard/account', { teamId, accountId });
  const getGlobalDashboard = async (query?: AnalyticsCriteria): Promise<GlobalAnalyticsResponse> => get('/analytics/dashboard/global', query, { customTimeout: ONE_MINUTE });

  // ------ similar posts/images
  const getSimilarImages = async (query: SimilarImagesQuery): Promise<SimilarImageResponse> => get('/admin/similarImages', query, { useAbort: true });
  const ignoreSimilarImage = async (id: string): Promise<void> => put(`/admin/similarImages/ignore/${id}`);
  const matchSimilarImage = async (id: string): Promise<void> => put(`/admin/similarImages/match/${id}`);
  const getSimilarPosts = async (postId: number): Promise<Post[]> => get(`/posts/${postId}/similar`);
  const arePostsMatchworthy = async (postId: number, compareId: number[]): Promise<boolean> => get(`/matches/score/${postId}`, { compareId });
  const generateSimilarImages = async (postId: number): Promise<number> => post('/admin/similarImages', { postId });

  // ------ authed twitter
  const getAvatarFromTwitter = async (username: string): Promise<string> => get(`/twitterProxy/${username}/avatar`);
  const addTwitterProfile = async (username: string) => post('/admin/twitterProfile', { username });

  // ------ authed instagram
  const addInstagramAccount = async (username: string) => put('/admin/instagramAccount', { username });

  // ------ authed tiktok
  const addTiktokProfile = async (username: string) => post('/admin/tiktokProfile', { username });
  const revokeTikTokAuth = async (id: number) => post(`/auth/tiktok/revoke/${id}`);

  // ------ authed youtube
  const addYoutubeProfile = async (username: string) => put('/admin/youtubeProfile', { username });

  // ------ twitch
  const addTwitchProfile = async (username: string) => put('/admin/twitchProfile', { username });
  const revokeTwitchAuth = async (id: number) => post(`/auth/twitch/revoke/${id}`);

  // ------ posting accounts
  const getPostingAccounts = async (): Promise<PostingAccount[]> => get('/users/postingAccounts');
  const addPostingAccount = async (account: Partial<PostingAccount>): Promise<PostingAccount> => post('/users/postingAccounts', account);
  const addPostingAccounts = async (accounts: Partial<PostingAccount>[], userId: number): Promise<PostingAccount[]> => post(`/users/postingAccounts/bulk/${userId}`, accounts);
  const deletePostingAccount = async (id: number) => httpDelete(`/users/postingAccounts/${id}`);
  const updatePostingAccount = async (id: number, account: Partial<PostingAccount>): Promise<PostingAccount> => put(`/users/postingAccounts/${id}`, account);
  const syncSocialLinkForPostingAccount = async (postingAccountId: number): Promise<User> => post(`/users/postingAccounts/${postingAccountId}/socialLink`);
  const deleteSocialLinkViaPostingAccount = async (postingAccountId: number): Promise<User> => httpDelete(`/users/postingAccounts/${postingAccountId}/socialLink`);
  const findByNetwork = async (networkId: number, username: string): Promise<NetworkUser> => get(`/users/postingAccounts/findByNetwork/${networkId}`, { username });
  const lookUpAllNetworks = async (username: string, includeExistingUser: boolean = false, ownedAccountsUserId?: number): Promise<AllNetworks> => get(`/users/postingAccounts/lookUpAllNetworks/${username}`, { includeExistingUser, ownedAccountsUserId });
  const checkForMatchingAccount = async (username: string): Promise<boolean> => put(`/users/postingAccounts/checkForMatchingAccount/${username}`);
  const enqueueHistoricalImportAdmin = async (userId: number, networkId?: number) => post('/admin/enqueueHistoricalImport', { networkId, userId });
  const enqueueBulkAddPostingAccounts = async (accounts: Account[], listName: string, roleId?: number) => post('/users/postingAccounts/import', { accounts, listName, roleId });
  const listTeamManagedPostingAccounts = async (accountId?: number): Promise<PostingAccount[]> => get('/users/postingAccounts/teamAccounts', { accountId });
  const adminPostingAccounts = async (query: PostingAccountCriteria): Promise<PostingAccountsSearchResponse> => get('/admin/listPostingAccountsForAdmin', query);

  // ------ network accounts
  const autoImportOn = async (account: string, network: number) => post(`/networkAccounts/${account}/${network}/autoImport`);
  const autoImportOff = async (account: string, network: number) => httpDelete(`/networkAccounts/${account}/${network}/autoImport`);
  const trackFollowersOn = async (account: string, network: number) => post(`/networkAccounts/${account}/${network}/trackFollowers`);
  const trackFollowersOff = async (account: string, network: number) => httpDelete(`/networkAccounts/${account}/${network}/trackFollowers`);
  const searchNetworkAccounts = async (query: PostingAccountCriteria): Promise<NetworkAccountsSearchResponse> => get('/networkAccounts', query);
  const updateNetworkAccountById = async (networkAccountId: number) => post(`/networkAccounts/${networkAccountId}/update`);

  // ------ user default resume
  const updateDefaultResume = async (resumeUrl: string): Promise<User> => put('/users/resumes', { resumeUrl });
  const clearResume = async (): Promise<User> => httpDelete('/users/resumes');

  // ------ teams
  const addTeam = async (team: Team): Promise<Team> => post('/teams', team);
  const updateTeam = async (id: number, team: Partial<Team>): Promise<Team> => put(`/teams/${id}`, team);
  const getTeams = async (): Promise<Team[]> => get('/teams');
  const getTeamsAdmin = async (query: AdminTeamQueryParams): Promise<Team[]> => get('/teams/admin', query);
  const addMember = async (teamId: number, inviteMember: Partial<TeamMember>): Promise<TeamMember> => post(`/teams/${teamId}/members/invite`, inviteMember);
  const deleteMember = async (teamId: number, teamMemberId: number): Promise<TeamMember> => httpDelete(`/teams/${teamId}/members`, { teamMemberId });
  const updateMember = async (teamId: number, memberId: number, member: Partial<TeamMember>): Promise<TeamMember> => put(`/teams/${teamId}/members/${memberId}`, member);
  const addTeamAccount = async (teamId: number, accountId: number): Promise<User> => post(`/teams/${teamId}/accounts`, { accountId });
  const deleteTeamAccount = async (teamId: number, accountId: number): Promise<User> => httpDelete(`/teams/${teamId}/accounts`, { accountId });
  const getTeamAccounts = async (teamId: number): Promise<TeamAccount[]> => get(`/teams/${teamId}/accounts`);
  const updateAccountSettings = async (teamId: number, accountId: number, settings: TeamAccountSettings) => put(`/teams/${teamId}/accounts`, { accountId, settings });
  const updateTeamAccountSettings = async (teamId: number, accountId: number, settings: TeamAccountSettings) => put(`/teams/${teamId}/accountSettings`, { accountId, settings });
  const resendTeamInvite = async (teamMemberId: number) => post(`/teams/${teamMemberId}/reinvite`);
  const ackTermsForAccount = async (teamId: number, accountId: number) => post(`/teams/${teamId}/acknowledge`, { accountId });
  const isAccountAlreadyManaged = async (accountId: number): Promise<boolean> => get(`/teams/accounts/${accountId}`);
  const toggleWorkForUs = async (teamId: number, account: Partial<TeamAccount>) => post(`/teams/${teamId}/workForUs`, account);
  const getTeamApiPermissions = async (teamId: number): Promise<ExternalApiPermission> => get(`/teams/${teamId}/permissions`);
  const deleteTeam = async (teamId: number) => httpDelete(`/teams/${teamId}`); // admin only
  const getAllTeamAccounts = async (): Promise<TeamAccount[]> => get('/admin/teamAccounts'); // admin only

  const getTeamStripeSession = async (teamId: number, successUrl: string, cancelUrl: string): Promise<StripeSessionResponse> => get(`/teams/${teamId}/session`, { successUrl, cancelUrl });
  const getTeamStripeCustomer = async (teamId: number): Promise<StripeCustomerResponse> => get(`/teams/${teamId}/stripeCustomer`);
  const getTeamSetupIntent = async (teamId: number): Promise<Stripe.SetupIntent> => get(`/teams/${teamId}/setupIntent`);

  const getStripePaymentMethods = async (teamId: number): Promise<StripePaymentMethod[]> => get(`/teams/${teamId}/paymentMethods`);
  const deleteStripePaymentMethod = async (teamId: number, paymentMethodId: string) => httpDelete(`/teams/${teamId}/paymentMethods`, { paymentMethodId });

  const addTeamSubscription = async (teamId: number, subscription: TeamSubscription): Promise<TeamSubscription> => post(`/teams/${teamId}/subscriptions`, subscription);
  const getTeamSubscription = async (teamId: number): Promise<TeamSubscription> => get(`/teams/${teamId}/subscriptions`);
  const previewSubscription = async (teamId: number, subscription: TeamSubscription): Promise<InvoicePreview> => post(`/teams/${teamId}/subscriptions/preview`, subscription);
  const updateTeamSubscription = async (teamId: number, subscription: TeamSubscription): Promise<TeamSubscription> => put(`/teams/${teamId}/subscriptions/change`, subscription);
  const cancelTeamSubscription = async (teamId: number, subscriptionId: number): Promise<TeamSubscription> => httpDelete(`/teams/${teamId}/subscriptions`, { id: subscriptionId });

  const getTeamInvoices = async (teamId: number): Promise<TeamSubscriptionInvoice[]> => get(`/teams/${teamId}/invoices`);

  const addApplicationRecipient = async (teamId: number, memberId: number) => put(`/teams/${teamId}/addApplicationRecipient/${memberId}`);
  const removeApplicationRecipient = async (teamId: number, memberId: number) => put(`/teams/${teamId}/removeApplicationRecipient/${memberId}`);

  // ------ milestones
  const getAllMilestones = async (): Promise<AdminMilestoneResponse> => get('/milestones');
  const addMilestone = async (milestone: Partial<Milestone>) => post('/milestones', milestone);
  const deleteMilestone = async (milestoneId: number) => httpDelete(`/milestones/${milestoneId}`);
  const updateMilestone = async (milestoneId: number, milestone: Partial<Milestone>) => put(`/milestones/${milestoneId}`, milestone);
  const getUserMilestones = async (userId: number): Promise<Milestone[]> => get(`/milestones/users/${userId}`);
  const getUserStreaks = async (userId: number) => get(`/milestones/streaks/${userId}`);
  const getAchievedMilestoneCount = async (userId: number): Promise<{achievedCount: number; totalCount: number}> => get(`/milestones/achieved/${userId}`);
  const getMyGondolaMilestones = async (userId: number): Promise<MyGondolaMilestones> => get(`/milestones/myGondola/${userId}`);
  const getTopNewMilestoneForUser = async (): Promise<Milestone> => get('/milestones/newest');

  // ------ post imports
  const importUrls = async (postURLs: string[], importName: string) => post('/posts/import', { postURLs, importName });
  const importPosts = async (posts: PostToImport[], importName: string): Promise<Track> => post('/bulk/importPosts', { posts, importName });

  // ------ scrape statues
  const getAllScraperAccounts = async (query?: ScraperAdminCriteria): Promise<ScraperStatusResponse> => get('/admin/scrapers/accounts', query);
  const scrapeAsap = async (accountId: number) => post(`/admin/scrapers/scrapeAsap/${accountId}`);
  const updateScrapePriority = async (accountId: number, priority: string) => put(`/admin/scrapers/${accountId}`, { priority });
  const getNetworkConfig = async (): Promise<NetworkStatus[]> => get('/admin/networkConfig');
  const updateNetworkConfig = async (networkId: number, status: boolean) => put(`/admin/networkConfig/${networkId}`, { status });
  const getAllJobsByAccountId = async (accountId: number): Promise<ScraperAccount> => get(`/admin/scrapers/${accountId}/jobs`);

  // ------ auth/google
  const signInWithGoogle = async (payload: GoogleAuthPayload): Promise<void> => post('/auth/google/signin', payload);

  // ------ auth/apple
  const signInWithApple = async (payload: AppleWebSignInCredential): Promise<void> => post('/auth/apple/signin/web', payload);

  /**
   * Send the code returned by Google login to the backend to see if the email address is in use
   * and return idToken and their authStatus.
   */
  const exchangeTokens = async (code: string): Promise<TokenExchangeResponse> => post('/auth/google/tokens', { code });

  // ------ auth/email
  const signUpWithEmail = async (payload: EmailCredential): Promise<void> => post('/auth/email/signup', payload);
  const saveUsername = async (username: string): Promise<void> => put('/users/username', { username });
  const signInWithEmail = async (payload: EmailCredential): Promise<void> => post('/auth/email/signin', payload);
  const signInWithMagicToken = async (magicToken: string): Promise<void> => post('/auth/email/signin/magic', magicToken);
  const getCredential = async (): Promise<EmailCredential> => get('/auth/email');
  const checkUncredentialedEmail = async (email: string): Promise<CredentialsForEmail> => get('/auth/email/check', { email });
  const sendVerifyCode = async (email: string, returnTo: string): Promise<void> => put('/users/verify', { email, returnTo });
  const sendMagicLinkEmail = async (email: string, returnTo: string): Promise<void> => post('/auth/email/magicLink', { email, returnTo });
  const verifyCode = async (email: string, code: string): Promise<void> => post('/users/verify', { email, code });

  // ------ replay
  const getAllReplayCards = async (query: any) => get('/admin/getAllReplayCards', query);
  const queueReplayRegenerate = async (userId: number) => post(`/admin/replayRegen/${userId}`);

  // ------ lotties
  const getReplaySlide = async (slide: string, userId: number) => get(`/lotties/replay/${slide}`, { userId });
  const getMilestoneCelebrate = async () => get('/lotties/milestone/celebrate');

  // ------ lists
  const addList = async (list: AddList): Promise<List> => post('/lists', list);
  const addUserToList = async (listId: number, userId: number) => post(`/lists/${listId}/user`, { userId });
  const removeUserFromList = async (listId: number, userId: number) => httpDelete(`/lists/${listId}/user`, { userId });
  const editList = async (list: List): Promise<List> => put(`/lists/${list.id}`, list);
  const deleteList = async (listId: number) => httpDelete(`/lists/${listId}`);
  const getUserLists = async (): Promise<List[]> => get('/lists');
  const bulkAddUsersToList = async (users: User[], listId: number) => post(`/lists/${listId}/users`, { users });
  const addVote = async (listId: number, userId: number): Promise<ListVote> => post(`/lists/${listId}/vote`, { userId });
  const removeVote = async (listId: number, listVoteId: number) => httpDelete(`/lists/${listId}/vote/${listVoteId}`);
  const updateListUserStatus = async (listId: number, listUserId: number, status: string): Promise<List> => put(`/lists/${listId}/user/${listUserId}`, { status });

  // ------ list members
  const addListMember = async (
    listId: number, memberId: number,
  ): Promise<ListMember> => post(`/lists/${listId}/member`, { memberId });
  const deleteListMember = async (listId: number, memberId: number) => httpDelete(`/lists/${listId}/member`, { memberId });

  // ------ credentials
  const updateCredential = async (credentialId: number, credential: EmailCredential): Promise<EmailCredential> => put(`/users/credentials/${credentialId}`, credential);

  // ------ connections
  const getConnections = async (): Promise<Connection[]> => get('/users/connections');
  const getManagedAccounts = async (): Promise<ManagedAccount[]> => get('/users/connections/managedAccounts');
  const deleteConnection = async (connectionId: number) => httpDelete(`/users/connections/${connectionId}`);

  // ------ jobs
  const getAllJobs = async (query: any): Promise<JobSearchResponse> => get('/jobs', query);
  const getJob = async (jobId: number): Promise<Job> => get(`/jobs/${jobId}`);
  const addJob = async (job: JobSchemaType): Promise<Job> => post('/jobs', job);
  const updateJob = async (jobId: number, job: JobSchemaType): Promise<Job> => put(`/jobs/${jobId}`, job);
  const addJobApplication = async (jobId: number, application: NewApplication): Promise<User> => post(`/jobs/${jobId}/application`, application);
  const getJobApplication = async (jobId: number): Promise<JobApplication> => get(`/jobs/${jobId}/application`);
  const deleteJobApplication = async (jobId: number): Promise<JobApplication> => httpDelete(`/jobs/${jobId}/application`);
  const getJobsByAccount = async (accountId: number): Promise<Job[]> => get(`/jobs/byAccount/${accountId}`);
  const deleteJob = async (jobId: number): Promise<JobApplication> => httpDelete(`/jobs/${jobId}`);
  const getApplicationById = async (applicationId: number): Promise<JobApplication> => get(`/jobs/applications/${applicationId}`);
  const getFeaturedJobs = async (query: any): Promise<Job[]> => get('/jobs/featured', query);
  const getTeamJobs = async (teamId: number, query?: any): Promise<JobSearchResponse> => get(`/teams/${teamId}/jobs`, query);

  // ------ concepts
  const getProjects = async (teamId: number): Promise<Project[]> => get('/projects', { teamId });
  const addProject = async (project: Partial<Project>): Promise<Project> => post('/projects', project);
  const updateProject = async (projectId: number, project: Partial<Project>): Promise<Project> => put(`/projects/${projectId}`, project);
  const getProject = async (projectId: number): Promise<Project> => get(`/projects/${projectId}`);
  const addProjectClient = async (projectId: number, clientId: number): Promise<Project> => post(`/projects/${projectId}/clients`, { id: clientId });
  const addConcept = async (concept: Partial<Concept>): Promise<Concept> => post('/concepts', concept);
  const updateConcept = async (conceptId: number, concept: Partial<Concept>): Promise<Concept> => put(`/concepts/${conceptId}`, concept);
  const addConceptMedia = async (conceptId: number, mediaId: number): Promise<Concept> => post(`/concepts/${conceptId}/media`, { id: mediaId });
  const removeConceptMedia = async (conceptId: number, mediaId: number): Promise<Concept> => httpDelete(`/concepts/${conceptId}/media/${mediaId}`);
  const searchConcepts = async (criteria: any): Promise<ConceptSearchResults> => get('/concepts', criteria, { returnFullJson: true });
  const deleteConcept = async (conceptId: number) => httpDelete(`/concepts/${conceptId}`);
  const addConceptTag = async (conceptId: number, value: string): Promise<TagValue[]> => post(`/concepts/${conceptId}/tags`, { value });
  const removeConceptTag = async (conceptId: number, tagId: number): Promise<TagValue[]> => httpDelete(`/concepts/${conceptId}/tags/${tagId}`);
  const deleteProject = async (projectId: number) => httpDelete(`/projects/${projectId}`);
  const searchProjects = async (criteria: any): Promise<ProjectSearchResults> => get('/projects/search', criteria, { returnFullJson: true });
  const addConceptLink = async (conceptId: number, newLink: Partial<Link>): Promise<Link> => post(`/concepts/${conceptId}/links`, newLink);
  const removeConceptLink = async (conceptId: number, linkId: number): Promise<Concept> => httpDelete(`/concepts/${conceptId}/links/${linkId}`);
  const duplicateConcept = async (conceptId: number, projectId?: number, projectName?: string): Promise<Concept> => post(`/concepts/${conceptId}/copy`, { projectId, projectName });
  const addProjectTag = async (projectId: number, value: string): Promise<TagValue[]> => post(`/projects/${projectId}/tags`, { value });
  const removeProjectTag = async (projectId: number, tagId: number): Promise<TagValue[]> => httpDelete(`/projects/${projectId}/tags/${tagId}`);
  const generateImage = async (conceptId: number): Promise<OpenAIImagesResponse> => get(`/concepts/${conceptId}/generate`);
  const addLinksToConcepts = async (conceptIds: number[], links: Partial<Link>[]): Promise<Link> => post('/concepts/links/bulk', { conceptIds, links });
  const addGeneratedImage = async (imageUrls: string[], conceptId: number): Promise<Media[]> => post(`/concepts/${conceptId}/addGeneratedImage`, imageUrls);
  const getProjectPostStats = async (projectId: number): Promise<{stats: Stats; collaborators: User[]}> => get(`/projects/${projectId}/stats`);

  // ------ questions
  const getQuestions = async (jobId: number): Promise<ApplicationQuestion[]> => get(`/jobs/${jobId}/questions`);
  const addQuestion = async (jobId: number, question: ApplicationQuestion): Promise<ApplicationQuestion> => post(`/jobs/${jobId}/question`, question);
  const updateQuestion = async (questionId: number, question: ApplicationQuestion): Promise<ApplicationQuestion> => put(`/jobs/questions/${questionId}`, question);
  const deleteQuestion = async (questionId: number) => httpDelete(`/jobs/questions/${questionId}`);

  // ------ auto credits 
  const upsertAutoCredit = async (autoCredit: AutoCredit, shouldAutoCreditHistoricalPosts: boolean): Promise<AutoCredit> => post('/users/postingAccounts/autoCredit', { autoCredit, shouldAutoCreditHistoricalPosts });
  const deleteAutoCredit = async (autoCreditId: number) => httpDelete(`/users/postingAccounts/autoCredit/${autoCreditId}`);
  const countAutoCreditsToCreate = async (postingAccountId: number): Promise<AutoCreditCount> => get(`/users/postingAccounts/${postingAccountId}/autoCredit/count`);

  // ------ event signups 
  const getEventSignup = async (eventName: string): Promise<EventSignup> => get('/eventSignups', { eventName });
  const addEventSignup = async (data: EventSignup): Promise<EventSignup> => post('/eventSignups', data);

  // ------ worked together 
  const getWorkedTogether = async (fromUser: number, toUser: number, randomize?: boolean): Promise<WorkedTogetherResponse> => get('/workedTogether', { fromUser, toUser, randomize });
  const getWorkedTogetherUses = async (): Promise<LimitedUseResponse> => get('/workedTogether/uses');

  // ------ content lists
  const addPostToList = async (listId: number, itemId: number) => post(`/contentLists/${listId}/post`, { itemId });
  const removeItemFromList = async (listId: number, itemId: number) => httpDelete(`/contentLists/${listId}/item/${itemId}`);
  const getProjectLists = async (teamId: number): Promise<ContentList[]> => get(`/contentLists/${teamId}`);
  const addItemToList = async (listId: number, itemId: number, itemType: string) => post(`/contentLists/${listId}/item/${itemId}`, { itemType, itemId });

  // ------ linkedin
  const getCurrentUsersPages = async (): Promise<any> => get('/linkedin/pages');

  // ------ caches
  const clearAPICache = async (type: string, key?: Record<string, any>) => post('/admin/caches/clear', { type, key });
  const getAPICaches = async (criteria: CacheSearchCriteria): Promise<ApiCacheResponse> => get('/admin/caches', criteria, { returnFullJson: true });

  // ------ admin
  const sendTestEmail = async (email: string, template: string) => post('/admin/sendTestEmail', { template, email });
  const getHeardAboutAdminOptions = async (): Promise<HeardAboutOption[]> => get('/admin/heardabout');
  const addHeardAboutOption = async (option: { name: string; weighting: number }) => post('/admin/heardabout', option);
  const deleteHeardAboutOption = async (id: number) => httpDelete(`/admin/heardabout/${id}`);

  // ------ replay
  const getAllGeneratedImages = async (query: any): Promise<ReplayAICardsResponse> => get('/replay', query);

  /**
   * ignoreErrors swallows any API errors and returns `defaultReturn` instead
   */
  async function ignoreErrors<T>(
    fnPromise: Promise<T>,
    defaultReturn: T | undefined,
  ) {
    try {
      const users = await fnPromise;
      return users;
    } catch (err) {
      // noop
    }
    return defaultReturn;
  }

  return {
    // TODO(Jordan): Don't return these internal generic functions.
    get,
    put,
    post,
    putFile,
    getCursorPagination,
    delete: httpDelete,

    ignoreErrors,

    // auth
    getAuth,
    token: hasToken(),

    // roles
    listAllRoles,
    listAllRolesAdmin,
    getUserRoles,
    getRoleById,
    createRole,
    updateRole,
    createSuggestedRole,
    getUserHiddenKeyRoles,
    createHiddenKeyRole,
    deleteHiddenKeyRole,
    listAllRoleTags,
    getRoleTagSuggestions,

    // tags
    listTagTypes,
    listAllTagValuesAdmin,
    tagValuesSearch,
    createNewTagValue,
    updateTagValue,
    deleteTagValue,
    createBulkTags,
    listUserTags,
    searchAccountNames,
    suggestTagsForPostId,
    addCreatorTag,
    getCreatorTagsByUser,
    deleteCreatorTag,

    // admin/reports
    listReports,
    getReport,

    // search posts
    getPostById,
    getPostByUrl,

    // posts
    createPinnedPost,
    deletePinnedPost,
    getPinnedPosts,
    createPostByUrl,
    deletePost,
    scrapePostMetrics,
    queueScrapePost,
    addTagToPost,
    deleteTagFromPost,
    reportCredit,
    toggleIsLive,
    toggleStatsPaused,
    toggleIsFeatured,
    toggleSensitiveContent,
    createManualPost,
    uploadMedia,
    uploadCompressedMedia,
    findMatchesForUpload,
    findMatchesForUploadViaImage,
    findMatchesForUploadViaUrl,
    getMediaComparisonResults,
    runMediaComparison,
    getMediaComparisonGroup,
    getMediaComparisonGroups,
    updateMediaComparison,
    updateMediaComparisonGroup,
    updateMediaComparisonGroupCredits,
    createMediaComparisonGroup,
    deleteMediaComparisonGroup,
    addMediaToComparisonGroup,
    deleteComparisonFromComparisonGroup,
    findPostsViaText,
    reindexPost,
    getTrendingPosts,
    getConceptsForPost,
    getCompareUses,
    ignoreSuggestedCredit,

    // stripe
    getStripeSetup,
    createStripeCheckout,
    createStripePortal,
    giveFreeMonth,
    endTrialEarly,

    // users
    createUser,
    listAllUsers,
    getUserById,
    getUserByUsername,
    getUserByPostId,
    updateOwnUser,
    updateOtherUser,
    updateAvatar,
    updateUserViaAdmin,
    claimUserReferral,
    getUserByReferralCode,
    createFeedback,
    getUserSettings,
    updateUserSettings,
    getTwitterUser,
    searchUsers,
    searchUsersForTeamMember,
    searchCreators,
    featuredCreators,
    exportCreatorsCSVReport,
    sendDirectMessage,
    getUserNextSteps,
    triggerWelcomeEmail,
    onboardUser,
    getCollaborators,
    suggestUsersForPost,
    getSuggestedUserToCredit,
    topWeeklyCreators,
    getAllUserReferrals,
    checkDeactivateUser,
    deactivateUser,
    getTwitterFollowers,
    saveUserData,
    updateUsername,
    queueReplayRecalc,
    getUserHistory,
    getUsernameAvailability,
    claimPersonalIgUsername,
    uploadResume,
    mergeUsers,
    getProfileStats,
    getMyGondolaStats,
    reindexUserPosts,
    getHeardAboutOptions,

    // postImports
    importUrls,
    importPosts,

    // credits
    createCredits,
    updateCredit,
    deleteCredit,
    getCreditCreator,

    // search
    getTopSearch,
    getProfileFilterSearch,
    getCreatorsFilterSearch,
    getCollaboratorRolesFilterSearch,
    getDashboardFilterSearch,

    // auditlogs and event tracking
    listCreditAuditsForPost,
    listTagAuditsForPost,
    trackEvent,
    trackEmailPwError,
    trackGoogleAuthError,

    // failedposts
    listFailedPosts,

    // blog
    listBlogPosts,
    refreshBlogPosts,

    //notifications
    getNotificationsStatus,
    getNotificationsByUser,
    archiveNotification,
    markNotificationRead,
    markAllNotificationsRead,

    //postMetrics
    getAllPostMetricsForPost,
    updatePostMetricsAndPost,

    //matches
    getMatch,
    addPostsToMatch,
    deleteMatchPost,
    getAllMatchesAdmin,
    unflagMatch,
    mergeMatches,
    getMatchStatsDifference,
    copyOriginalPostCreditsToAll,
    getMatchMetrics,

    //filter post
    getExplorePosts,
    getProfilePosts,
    getManagerPosts,

    exportCSVReportForProfile,
    exportCSVReportForExplore,

    getPostVectorViaText,

    // analytics
    getDashboard,
    getDashboardStats,
    clearDashboardCache,
    getDashboardAccount,
    getGlobalDashboard,

    // similar posts/images
    getSimilarPosts,
    getSimilarImages,
    ignoreSimilarImage,
    matchSimilarImage,
    arePostsMatchworthy,
    generateSimilarImages,

    // authed twitter
    getAvatarFromTwitter,
    addTwitterProfile,

    // authed instagram
    addInstagramAccount,

    // authed tiktok
    addTiktokProfile,
    revokeTikTokAuth,

    // authed youtube
    addYoutubeProfile,

    // twitch
    addTwitchProfile,
    revokeTwitchAuth,

    // posting accounts
    addPostingAccount,
    addPostingAccounts,
    deletePostingAccount,
    updatePostingAccount,
    getPostingAccounts,
    syncSocialLinkForPostingAccount,
    deleteSocialLinkViaPostingAccount,
    findByNetwork,
    lookUpAllNetworks,
    checkForMatchingAccount,
    enqueueHistoricalImportAdmin,
    enqueueBulkAddPostingAccounts,
    listTeamManagedPostingAccounts,
    adminPostingAccounts,

    // network accounts
    searchNetworkAccounts,
    autoImportOn,
    autoImportOff,
    trackFollowersOn,
    trackFollowersOff,
    updateNetworkAccountById,

    // default resume
    updateDefaultResume,
    clearResume,

    // connections
    getConnections,
    getManagedAccounts,
    deleteConnection,

    // teams
    addTeam,
    getTeams,
    getTeamsAdmin,
    updateTeam,
    addMember,
    deleteMember,
    updateMember,
    addTeamAccount,
    deleteTeamAccount,
    getTeamAccounts,
    updateAccountSettings,
    updateTeamAccountSettings,
    resendTeamInvite,
    ackTermsForAccount,
    isAccountAlreadyManaged,
    toggleWorkForUs,
    getTeamApiPermissions,
    deleteTeam,
    getAllTeamAccounts,

    getTeamStripeSession,
    getTeamStripeCustomer,
    getTeamSetupIntent,
    getStripePaymentMethods,
    deleteStripePaymentMethod,

    addTeamSubscription,
    getTeamSubscription,
    previewSubscription,
    updateTeamSubscription,
    cancelTeamSubscription,

    getTeamInvoices,

    addApplicationRecipient,
    removeApplicationRecipient,

    // milestones
    getAllMilestones,
    addMilestone,
    deleteMilestone,
    updateMilestone,
    getUserMilestones,
    getUserStreaks,
    getAchievedMilestoneCount,
    getMyGondolaMilestones,
    getTopNewMilestoneForUser,

    // scrape statues
    getAllScraperAccounts,
    scrapeAsap,
    getNetworkConfig,
    updateNetworkConfig,
    updateScrapePriority,
    getAllJobsByAccountId,

    // auth/google
    signInWithGoogle,
    exchangeTokens,

    // auth/apple
    signInWithApple,

    // auth/email
    signUpWithEmail,
    saveUsername,
    signInWithEmail,
    signInWithMagicToken,
    getCredential,
    checkUncredentialedEmail,
    sendVerifyCode,
    verifyCode,
    sendMagicLinkEmail,

    // replay
    getAllReplayCards,
    queueReplayRegenerate,

    // lotties
    getReplaySlide,
    getMilestoneCelebrate,

    // lists
    getUserLists,
    addList,
    addUserToList,
    removeUserFromList,
    editList,
    deleteList,
    bulkAddUsersToList,
    addVote,
    removeVote,
    updateListUserStatus,

    // list members,
    addListMember,
    deleteListMember,

    // credentials
    updateCredential,

    // jobs
    getAllJobs,
    getJob,
    addJob,
    updateJob,
    addJobApplication,
    getJobApplication,
    deleteJobApplication,
    getJobsByAccount,
    deleteJob,
    getApplicationById,
    getTeamJobs,
    getFeaturedJobs,

    // concepts
    getProjects,
    addProject,
    updateProject,
    getProject,
    addProjectClient,
    addConcept,
    updateConcept,
    addConceptMedia,
    removeConceptMedia,
    searchConcepts,
    deleteConcept,
    addConceptTag,
    removeConceptTag,
    deleteProject,
    searchProjects,
    addConceptLink,
    removeConceptLink,
    duplicateConcept,
    addProjectTag,
    removeProjectTag,
    generateImage,
    addGeneratedImage,
    addLinksToConcepts,
    getProjectPostStats,

    // auto credits 
    upsertAutoCredit,
    deleteAutoCredit,
    countAutoCreditsToCreate,

    getEventSignup,
    addEventSignup,

    getWorkedTogether,
    getWorkedTogetherUses,

    getQuestions,
    addQuestion,
    updateQuestion,
    deleteQuestion,

    // content lists
    addPostToList,
    removeItemFromList,
    getProjectLists,
    addItemToList,

    // linkedin
    getCurrentUsersPages,

    // caches
    clearAPICache,
    getAPICaches,

    // admin
    sendTestEmail,
    getHeardAboutAdminOptions,
    addHeardAboutOption,
    deleteHeardAboutOption,

    // replay
    getAllGeneratedImages,
  };
};
