import { CreatorCollaborator, CreatorsListRole, KeyRole } from 'lib/types';
import { CreatorsListUser } from 'lib/types/users';
import {
  Avatar as Icon, Chip,
} from '@mui/material';
import { Favorite, Visibility } from '@mui/icons-material';
import {
  abbreviateNum, userIsPro,
} from 'lib/helpers';
import { Avatar } from 'components/Avatar';
import { imageAvatarUrl } from 'lib/utils/imageUtils';
import { modelToSlug } from 'lib/utils/modelSlugUtils';
import { ProfileLink } from 'components/ProfileLink';
import { SaveToListButton } from 'components/SaveToList';
import { CollabAvatar } from 'components/CollabAvatar';
import { MailButton } from 'components/MailButton';
import { CreatorCardClickContext } from 'lib/types/events';

interface Props {
  user: CreatorsListUser;
  showLinks?: boolean;
  showPro?: boolean;
  context?: CreatorCardClickContext;
}

export const CreatorInfoCompact = ({
  user,
  showLinks = false,
  showPro = false,
  context,
}: Props) => {
  const getUserRoles = () => (
    user.roles
      .filter((r: CreatorsListRole) => user.hiddenRoles
        .every((hr: KeyRole) => hr.roleId !== r.id))
      .slice(0, 3).map((r: CreatorsListRole, i: number) => (
        <div
          /* hidding last one on mobile */
          {...(i >= 2 && { className: 'hidden md:block' })}
        >
          <ProfileLink
            key={r.id}
            user={user}
            query={{ role: modelToSlug(r, 'name') }}
            disabled={!showLinks || !user.termsAcknowledged}
            context={context}
          >
            <Chip
              clickable={showLinks && user.termsAcknowledged}
              avatar={<Icon sx={{ backgroundColor: 'white' }}>{abbreviateNum(r?.roleCount || 0, 0)}</Icon>}
              label={r.name}
            />
          </ProfileLink>
        </div>
      ))
  );

  const getCollaborators = () => user.collaborators
    .slice(0, 5).map((c: CreatorCollaborator) => (
      <CollabAvatar
        key={c.id}
        collab={c}
        disabled={!showLinks}
      />
    ));

  if (!user?.username) {
    return null;
  }

  return (
    <div className="hover:bg-hintgray">
      <div className="grid grid-cols-2 md:grid-cols-8 relative py-2">
        <div className="flex col-span-2 md:col-span-3 lg:col-span-2 w-full px-2">
          <div className="pr-2">
            <SaveToListButton
              userId={user.id}
              listsFromProps={user.savedToLists || []}
            />
            {user?.contactAllowed
              && (
                <div className="text-orange">
                  <MailButton user={user} fontSize="medium" />
                </div>
              )}
          </div>
          <ProfileLink
            user={user}
            disabled={!showLinks}
            linkToExplore={user.isHidden}
            context={context}
          >
            <Avatar
              src={imageAvatarUrl(user.avatarUrl, 80)}
              userId={user.id}
              isPro={showPro && userIsPro(user)}
              alt={user.name}
              style={{ maxWidth: 60 }}
            />
          </ProfileLink>
          <div className="pl-2">
            <h6 className="font-bold">
              <ProfileLink
                user={user}
                disabled={!showLinks}
                linkToExplore={user.isHidden}
                context={context}
              >
                {user.name}
              </ProfileLink>
            </h6>
            <div className="lg:hidden">
              <Favorite fontSize="small" />
              <span>{abbreviateNum(user.currentLikes, 0)}</span>
              {user.currentCombinedViews > 0
                && (
                  <>
                    &nbsp;
                    <Visibility fontSize="small" />
                    <span>{abbreviateNum(user.currentCombinedViews, 0)}</span>
                  </>
                )}
            </div>
            <div className="md:hidden">
              {user.location?.name}
            </div>
          </div>
        </div>
        <div className="col-span-1 hidden lg:block">
          <Favorite fontSize="small" />
          <span>{abbreviateNum(user.currentLikes, 0)}</span>
          {user.currentCombinedViews > 0
            && (
              <>
                &nbsp;
                <Visibility fontSize="small" />
                <span>{abbreviateNum(user.currentCombinedViews, 0)}</span>
              </>
            )}
        </div>
        <div className="col-span-1 hidden md:block">
          {user.location?.name}
        </div>
        <div className="col-span-2 flex py-2 lg:py-0 gap-1 flex-wrap w-full">
          {getUserRoles()}
        </div>
        <div className="col-span-2 hidden md:block">
          {user.collaborators.length !== 0
          && (
            <>
              <div className="pr-1 text-sm font-semibold">Top Collaborators:</div>
              <div className="flex flex-wrap gap-1">
                {user.collaborators
                  && getCollaborators()}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
