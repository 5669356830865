import {
  Network, NETWORK_IDS, NETWORKS, POSTING_ACCOUNT_TYPE,
} from 'lib/constants';
import { NetworkIcon } from 'elements/NetworkIcon';
import { trackClickSocialMediaLinkEvent } from 'lib/hooks/useEventTracking';
import { User } from 'lib/types/users';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { MailButton } from 'components/MailButton';
import { postingAccountToUrl, userIsAdmin } from 'lib/helpers';
import { AddPostingAccountsModal } from 'components/AddPostingAccountsModal';
import { useModal } from 'mui-modal-provider';
import { Add } from '@mui/icons-material';
import { MobileTooltip } from 'components/MobileTooltip';
import { useState } from 'react';

interface Props {
  profileUser: User;
  showColor?: boolean;
}

export const SocialMediaRow = ({ profileUser, showColor }: Props) => {
  const API = useApi();
  const currentUser = useCurrentUser();
  const { showModal } = useModal();

  const [_postingAccounts, setPostingAccounts] = useState(profileUser.postingAccounts || []);

  const currentUserCanAddPostingAccount = userIsAdmin(currentUser) || currentUser?.hasPaidTeam;

  const addPostingAccount = async () => {
    const modal = showModal(AddPostingAccountsModal, {
      user: profileUser,
      postingAccounts: _postingAccounts,
      onAdd: async (accountsToAdd) => {
        const newPostingAccounts = await API.addPostingAccounts(accountsToAdd, profileUser.id);

        setPostingAccounts([..._postingAccounts, ...newPostingAccounts]);
        modal.destroy();
      },
      onClose: () => {
        modal.destroy();
      },
    });
  };

  const hasSocialMediaOrContact = () => {
    const {
      email, personalUrl, postingAccounts,
    } = profileUser;
    const ownerAccounts = postingAccounts?.filter(
      (pa) => pa.type === POSTING_ACCOUNT_TYPE.owner || pa.showOnProfile,
    );
    return !!(profileUser && ((email && profileUser?.publicSettings?.contactAllowed)
      || personalUrl || ownerAccounts?.length));
  };

  if (!hasSocialMediaOrContact() && !currentUserCanAddPostingAccount) {
    return null;
  }

  const networkButton = (network: Network, networkUrl: string) => {
    if (!networkUrl.length) {
      return null;
    }
    return (
      <a
        key={`network-${network.id}-${networkUrl}`}
        href={networkUrl}
        title={`open ${network.title}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => trackClickSocialMediaLinkEvent(
          API, network.name, profileUser.id,
        )}
      >
        <NetworkIcon
          network={network.id}
          showColor={showColor}
        />
      </a>
    );
  };

  return (
    <div id="profile-social-media" className="flex flex-wrap justify-center items-center gap-4">
      {!!profileUser.email && !!profileUser?.publicSettings?.contactAllowed
        && (<MailButton user={profileUser} />)}
      {_postingAccounts?.map((pa) => {
        if (!pa.showOnProfile || pa.type === POSTING_ACCOUNT_TYPE.manager) {
          return null;
        }
        const networkUrl = postingAccountToUrl(pa.network, pa.account);
        if (!networkUrl.length) {
          return null;
        }
        const network = NETWORKS[pa.network];
        return networkButton(network, networkUrl);
      })}
      {!!profileUser.personalUrl && networkButton(
        NETWORKS[NETWORK_IDS.personal], profileUser.personalUrl,
      )}
      {currentUserCanAddPostingAccount && (
        <MobileTooltip title="Add Posting Account">
          <span className="cursor-pointer">
            <Add color="primary" onClick={addPostingAccount} />
          </span>
        </MobileTooltip>
      )}
    </div>
  );
};
