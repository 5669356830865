import React from 'react';

interface Props {
  emoji: string;
  description: string;
}

export const Emoji = ({
  emoji,
  description,
}: Props) => <span role="img" aria-label={description}>{emoji}</span>;
